import { store } from 'index'
import { getIntl } from 'localization'
import React, { useState, useEffect } from 'react'
import VaultForm from './components/VaultForm'
import { decryptData } from './components/VaultForm/functions'
import PrivateKeyView from './components/VaultForm/PrivateKeyView'
import VaultList from './components/VaultList'
import { App } from 'antd'

const VaultContainer = ({ setHeader, vaults: { activeItem } }) => {
  const [viewMode, setViewMode] = useState('list')
  const { modal } = App.useApp()
  const actions = {
    getVaults: filters => {
      store.dispatch({
        type: 'vaults/GET_VAULTS',
        payload: { filters },
      })
    },
    addVault: () => {
      setViewMode('newVault')
    },
    createVault: data => {
      store.dispatch({
        type: 'vaults/CREATE_VAULT',
        payload: { data, modal },
      })
      setViewMode('list')
    },
    updateVault: data => {
      store.dispatch({
        type: 'vaults/UPDATE_VAULT',
        payload: { ...data },
      })
    },
    lockVault: () => {
      setViewMode('list')
      store.dispatch({
        type: 'vaults/SET_STATE',
        payload: { activeItem: {} },
      })
    },
    unlockVault: item => {
      setViewMode('privateKeyView')
      store.dispatch({
        type: 'vaults/SET_STATE',
        payload: { activeItem: item },
      })
    },
    decryptVault: key => {
      const decrypted = decryptData(activeItem.payload, key)
      if (decrypted) {
        store.dispatch({
          type: 'vaults/SET_STATE',
          payload: { activeItem: { ...activeItem, decrypted } },
        })
        setViewMode('editVault')
        return true
      }
      return false
    },
  }
  const handleCancel = () => {
    setViewMode('list')
  }
  const views = {
    list: <VaultList actions={actions} />,
    newVault: <VaultForm onCreate={actions.createVault} handleCancel={handleCancel} />,
    editVault: (
      <VaultForm vault={activeItem} onUpdate={actions.updateVault} handleCancel={handleCancel} />
    ),
    privateKeyView: (
      <PrivateKeyView decryptVault={actions.decryptVault} onCancel={() => setViewMode('list')} />
    ),
  }

  const viewTitles = {
    list: getIntl('Vaults'),
    newVault: getIntl('Add New Vault'),
    editVault: getIntl('Vault Detail'),
    privateKeyView: getIntl('vault.privateKey.modalTitle'),
  }

  useEffect(() => {
    if (setHeader) {
      setHeader(viewTitles[viewMode])
    }
  }, [viewMode])

  useEffect(() => {
    return () => {
      store.dispatch({
        type: 'vaults/SET_STATE',
        payload: { vaults: [] },
      })
    }
  }, [])

  return <div>{views[viewMode]}</div>
}

export default VaultContainer
