/* eslint-disable import/prefer-default-export */
import { getList, getOneByID, updateRecord } from 'api/requests'

const resetCache = () => {
  console.info('Resetting library cache & version')
  localStorage.removeItem('agent.lastKnownVersion')
  localStorage.removeItem('agent.libraries')
}

const getLibraries = async () => {
  const data = await getList('libraries', { pageSize: 150 })
  return data
}
/**
 *
 * @returns Libraries from localStorage if stored
 */
export const getCachedLibraries = () => {
  let libraries = []
  const data = localStorage.getItem('agent.libraries')
  try {
    libraries = JSON.parse(data)
  } catch (e) {
    resetCache()
    libraries = false
  }
  return libraries
}
/**
 *  Saves given library data and version in to localStorage
 * @param {[]} libraries
 * @param {string} version ex.v21.1.2
 */
export const setCachedLibraries = (libraries, version) => {
  console.info(`Setting library cache with version : ${version}`)
  try {
    localStorage.setItem('agent.lastKnownVersion', version)
    localStorage.setItem('agent.libraries', JSON.stringify(libraries))
  } catch (e) {
    console.log(e)
  }
}

export const getLibraryItemVersionById = async id => {
  const data = await getOneByID('libraryitemversions', id)
  return data
}

export const updateLibraryItemVersion = async (id, data) => {
  const result = await updateRecord('libraryitemversions', id, data)
  return result
}

export { getLibraries }
