/* eslint-disable import/no-cycle */

import React from 'react'
import { Dropdown } from 'antd'
import {
  BugOutlined,
  CheckCircleOutlined,
  CheckOutlined,
  CopyFilled,
  CopyOutlined,
  DeleteFilled,
  EditFilled,
  ForwardOutlined,
  StopOutlined,
} from '@ant-design/icons'
import { connect } from 'react-redux'
import { IfNode, TaskNode, TryCatchNode, WhileNode, ForEachNode } from './StyledComponents'

import NodeInnerCustom from './NodeInnerCustom'

import { taskStateActions } from '../TaskEditor'
import { scenarioStateActions } from '../ScenarioEditor'
import { FormattedHTMLMessage } from 'react-intl'
import RenameIcon from 'components/CustomIcons/Rename'
import { isMac } from 'utils/osRelated'
import {
  handleCopy,
  handleCopyId,
  handleEditNode,
  handleEscape,
  handleRename,
  handleDevOptions,
} from '../EditorHelpers'

const mapStateToProps = (state, ownProps) => ({
  scenario_editor: state.scenario_editor,
  developerMode: state.settings.developerMode,
  active_scenario: state.active_scenario,
  router: state.router,
  ...ownProps,
})

const NodeCustom = React.forwardRef((props, ref) => {
  const {
    node,
    config,
    children,
    isSelected,
    developerMode,
    scenario_editor: { scene, renamingNode = false },
    active_scenario,
    router,
    ...otherProps
  } = props

  const { taskId } = router.location.query
  const selectedNodes =
    scene === 'task'
      ? active_scenario.content.chart.nodes[taskId].properties.chart?.picked?.nodes || []
      : active_scenario.content.chart?.picked?.nodes || []

  const isSkipped = !!props.node?.properties?.detail?.devOptions?.skip
  const chart =
    scene === 'task'
      ? active_scenario.content.chart.nodes[taskId].properties.chart
      : active_scenario.content.chart
  const actions = scene === 'task' ? taskStateActions : scenarioStateActions

  const handleContextMenuSelect = ({ key, domEvent }) => {
    domEvent.preventDefault()
    domEvent.stopPropagation()
    switch (key) {
      case 'delete':
        actions.onDeleteKey({})
        break
      case 'edit':
        handleEditNode(node)
        break
      case 'copy':
        handleCopy(taskId)
        break
      case 'copyId':
        handleCopyId(node.id)
        break
      case 'rename':
        handleRename(true)
        break
      case 'skip':
        selectedNodes.forEach(selectedNodeId => {
          handleDevOptions(chart.nodes[selectedNodeId], 'skip', node, scene)
        })

        break
      case 'debug':
        selectedNodes.forEach(selectedNodeId => {
          handleDevOptions(chart.nodes[selectedNodeId], 'debug', node, scene)
        })

        break
      case 'continue_on_error':
        selectedNodes.forEach(selectedNodeId => {
          handleDevOptions(chart.nodes[selectedNodeId], 'continue_on_error', node, scene)
        })

        break
      default:
        break
    }
  }

  const handleContextNodeClick = e => {
    e.stopPropagation()
    if (!isSelected) actions.onNodeClick({ nodeId: node.id })
  }

  const handleKeyDown = e => {
    e.stopPropagation()
    e.preventDefault()
    if (e[isMac() ? 'metaKey' : 'ctrlKey'] && e.code === 'KeyA') {
      actions.onSelectAll()
    }
    if (e[isMac() ? 'metaKey' : 'ctrlKey'] && e.code === 'KeyC') {
      handleCopy(taskId)
    }
    if (e.code === 'F2') {
      if (chart?.picked?.nodes.length === 1) {
        handleRename(true)
      }
    }
    if (e.code === 'Escape') {
      handleEscape()
    }
    if (e.code === 'Backspace' || e.code === 'Delete') {
      actions.onDeleteKey({})
    }
  }

  const items = [
    {
      key: 'edit',
      show: node.type === 'node' && scene !== 'task',
      icon: <EditFilled />,
      disabled: chart && chart.picked?.nodes.length !== 1,
      label: <FormattedHTMLMessage id="Edit" />,
    },
    {
      key: 'copy',
      icon: <CopyFilled />,
      show: isSelected && node.type === 'node',
      label: <FormattedHTMLMessage id="Copy" />,
    },
    {
      key: 'rename',
      icon: <RenameIcon />,
      show: isSelected && node.type === 'node',
      disabled: chart && chart.picked?.nodes.length !== 1,
      label: <FormattedHTMLMessage id="Rename" />,
    },
    { type: 'divider', show: isSelected && node.type === 'node' },
    {
      key: 'delete',
      icon: <DeleteFilled />,
      label: <FormattedHTMLMessage id="Delete" />,
    },
    {
      type: 'divider',
    },
    {
      key: 'copyId',
      label: <FormattedHTMLMessage id="Copy Id" />,
      icon: <CopyOutlined />,
      disabled: selectedNodes.length > 1,
    },
    {
      type: 'divider',
      show: isSelected && node.type === 'node' && scene === 'task' && developerMode,
    },
    {
      key: 'skip',
      icon: node.properties.detail.devOptions?.skip ? <CheckCircleOutlined /> : <StopOutlined />,
      show: isSelected && node.type === 'node',
      label: node.properties.detail.devOptions?.skip ? (
        <FormattedHTMLMessage id="Enable" />
      ) : (
        <FormattedHTMLMessage id="Disable" />
      ),
    },
    {
      key: 'debug',
      icon: <BugOutlined />,
      show: isSelected && node.type === 'node' && scene === 'task' && developerMode,
      label: (
        <>
          <FormattedHTMLMessage id="Debug" />
          {node.properties.detail.devOptions?.debug && <CheckOutlined className="ml-1" />}
        </>
      ),
    },
    {
      key: 'continue_on_error',
      icon: <ForwardOutlined />,
      show: isSelected && node.type === 'node' && scene === 'task' && developerMode,
      label: (
        <>
          <FormattedHTMLMessage id="Continue On Error" />
          {node.properties.detail.devOptions?.continue_on_error && (
            <CheckOutlined className="ml-1" />
          )}
        </>
      ),
    },
  ]

  const menu = {
    items: items.filter(item => item.show !== false).map(({ show, ...item }) => item),
    style: { outline: '1px solid #df255a55' },
    onClick: handleContextMenuSelect,
  }
  let component = (
    <Dropdown menu={menu} trigger={['contextMenu']}>
      <TaskNode
        ref={ref}
        isSelected={isSelected}
        isSkipped={isSkipped}
        {...otherProps}
        onDoubleClick={() => {
          if (scene === 'scenario') {
            handleEditNode(node)
          }
        }}
        onContextMenu={e => handleContextNodeClick(e)}
        onKeyDown={handleKeyDown}
      >
        {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
        <NodeInnerCustom {...props} />
        {node.properties.detail.devOptions?.debug && (
          <BugOutlined
            style={{
              position: 'absolute',
              top: '-6px',
              right: '6px',
              backgroundColor: 'white',
              border: '1px solid',
              borderRadius: '6px',
              padding: '1px',
            }}
          />
        )}
        {node.properties.detail.devOptions?.continue_on_error && (
          <ForwardOutlined
            style={{
              position: 'absolute',
              top: '-6px',
              right: node.properties.detail.devOptions?.debug ? '24px' : '6px',
              backgroundColor: 'white',
              border: '1px solid',
              borderRadius: '6px',
              padding: '1px',
            }}
          />
        )}
      </TaskNode>
    </Dropdown>
  )

  switch (node.type) {
    case 'if':
      component = (
        <Dropdown menu={menu} trigger={['contextMenu']}>
          <IfNode
            ref={ref}
            isSelected={isSelected}
            isSkipped={isSkipped}
            {...otherProps}
            color={node.properties.color}
            onContextMenu={e => handleContextNodeClick(e)}
          >
            {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
            <NodeInnerCustom {...props} />
          </IfNode>
        </Dropdown>
      )
      break
    case 'while':
      component = (
        <Dropdown menu={menu} trigger={['contextMenu']}>
          <WhileNode
            ref={ref}
            isSelected={isSelected}
            isSkipped={isSkipped}
            {...otherProps}
            color={node.properties.color}
            onContextMenu={e => handleContextNodeClick(e)}
          >
            {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
            <NodeInnerCustom {...props} />
          </WhileNode>
        </Dropdown>
      )
      break
    case 'forEach':
      component = (
        <Dropdown menu={menu} trigger={['contextMenu']}>
          <ForEachNode
            ref={ref}
            isSelected={isSelected}
            isSkipped={isSkipped}
            {...otherProps}
            color={node.properties.color}
            onContextMenu={e => handleContextNodeClick(e)}
          >
            {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
            <NodeInnerCustom {...props} />
          </ForEachNode>
        </Dropdown>
      )
      break
    case 'tryCatch':
      component = (
        <Dropdown menu={menu} trigger={['contextMenu']}>
          <TryCatchNode
            ref={ref}
            isSelected={isSelected}
            isSkipped={isSkipped}
            {...otherProps}
            color={node.properties.color}
            onContextMenu={e => handleContextNodeClick(e)}
          >
            {React.Children.map(children, child => React.cloneElement(child, { ...props }))}
            <NodeInnerCustom {...props} />
          </TryCatchNode>
        </Dropdown>
      )
      break
    default:
      break
  }
  return component
})

export default connect(mapStateToProps, null, null, { forwardRef: true })(NodeCustom)
