import React from 'react'
import { Button, List } from 'antd'
import { UnlockOutlined } from '@ant-design/icons'
import { getIntl } from 'localization'

const VaultItem = ({ item, actions }) => {

  return (
    <List.Item>
      <List.Item.Meta
        title={
          <>
            {item.name}
            <Button
              size="small"
              className="float-right"
              icon={<UnlockOutlined />}
              onClick={() => actions.unlockVault(item)}
            >
              {getIntl('Unlock')}
            </Button>
          </>}
        description={item.description}
      />
    </List.Item>
  )
}

export default VaultItem
