/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
/* eslint-disable no-nested-ternary */
import React, { useRef, useEffect, useReducer, useState } from 'react'
import { connect } from 'react-redux'
import { FlowChart, actions } from 'custom-libraries/react-flow-chart'
import { App, Spin } from 'antd'
import { getIntl } from 'localization'
import { deletePairNode, findSelectedPair } from 'utils/scenario_designer_utils'
import { setModified } from 'utils/scenarioChangeHandler'
import { initialChart, newNode } from './helper'
import { CanvasOuterCustom } from './styledComponents'

import './style.css'

import NodeCustom from './CustomNodes/NodeCustom'
import Dummy from './CustomNodes/Dummy'
import PortCustom from './CustomNodes/PortCustom'
import LinkCustom from './CustomNodes/LinkCustom'
import CanvasInner from './CustomNodes/CanvasInner'
import attachShortCutListeners, { detachListeners } from './shortcutListeners'

let scenarioStateActions

const mapStateToProps = ({ active_scenario, scenario_editor, settings }) => ({
  active_scenario,
  scenario_editor,
  settings,
})

const ScenarioEditor = ({
  active_scenario,
  settings,
  scenario_editor: { loading, clipboard, nodeAddMode },
  dispatch,
}) => {
  const chartRef = useRef()
  const { modal } = App.useApp()

  const scenarioChart = active_scenario?.content?.chart || initialChart
  // Old scenarios temporary picked attribute fix
  if (!scenarioChart.picked?.nodes) {
    Object.assign((scenarioChart.picked = { nodes: [], links: [], ports: [] }))
  }
  // *
  const setScenarioChart = chart => {
    dispatch({
      type: 'active_scenario/SET_SCENARIO_CHART',
      payload: { chart },
    })
  }

  const isActiveScenario = active_scenario.id

  const confirmDeletion = () => {
    return new Promise(resolve => {
      modal.confirm({
        content: getIntl('confirmation.general.delete'),
        onOk: () => resolve(true),
        onCancel: () => resolve(false),
        okText: getIntl('Delete'),
      })
    })
  }

  scenarioStateActions = Object.keys(actions).reduce((obj, key) => {
    obj[key] = async (...args) => {
      const action = actions[key]
      if (key === 'onDeleteKey') {
        const confirmation = await confirmDeletion()
        if (!confirmation) return scenarioChart
        setModified('scenario')
        const pairs = findSelectedPair(scenarioChart)
        if (pairs && pairs.length > 0) {
          deletePairNode(scenarioChart, pairs)
        }
      }

      const newChartTransformer = action(...args)
      const newChart = newChartTransformer(scenarioChart)
      const modifiedChart = { ...scenarioChart, ...newChart }
      setScenarioChart({ ...scenarioChart, ...newChart })
      if (key === 'onCanvasDrop') {
        setModified('scenario')
        dispatch({
          type: 'active_scenario/SET_STATE',
          payload: {
            content: { ...active_scenario.content, chart: modifiedChart },
          },
        })
      }
      if (key === 'onCanvasClick') {
        handleCanvasClick(args[0])
      }

      if (key === 'onContextMenu') {
        handleCanvasRightClick(args[0])
      }
      return newChart
    }
    return obj
  }, {})

  const handleCanvasRightClick = ({ position }) => {
    if (clipboard.data && clipboard.mode === 'scenario') {
      dispatch({
        type: 'scenario_editor/CLIPBOARD_PASTE',
        modal,
        payload: { position },
      })
      return true
    }
  }

  const handleCanvasClick = ({ position }) => {
    if (nodeAddMode) {
      switch (nodeAddMode) {
        case 'task':
          newNode({ type: 'task', position }, scenarioStateActions.onCanvasDrop)
          break
        case 'if_task':
          newNode({ type: 'if_task', position }, scenarioStateActions.onCanvasDrop)
          break
        case 'while_task':
          newNode({ type: 'while_task', position }, scenarioStateActions.onCanvasDrop)
          break
        case 'tryCatch_task':
          newNode({ type: 'tryCatch_task', position }, scenarioStateActions.onCanvasDrop)
          break
        case 'forEach_task':
          newNode({ type: 'forEach_task', position }, scenarioStateActions.onCanvasDrop)
          break
        default:
          break
      }
      dispatch({
        type: 'scenario_editor/SET_STATE',
        payload: { nodeAddMode: false },
      })
    }
  }

  useEffect(() => {
    setScenarioChart(active_scenario.content.chart)
    attachShortCutListeners('scenario', scenarioStateActions)
    return () => {
      detachListeners()
    }
  }, [active_scenario.id])

  return loading ? (
    <Spin />
  ) : (
    <div
      ref={chartRef}
      style={{
        overflow: 'scroll',
        height: '100%',
      }}
    >
      <FlowChart
        chart={scenarioChart || initialChart}
        callbacks={scenarioStateActions}
        isTask={false}
        config={{
          isActiveScenario,
          nodeAddMode,
          snapToGrid: true,
        }}
        Components={{
          Node: NodeCustom,
          NodeInner: Dummy,
          Port: PortCustom,
          Link: LinkCustom,
          CanvasOuter: CanvasOuterCustom,
          CanvasInner, // CanvasInnerCustom,
        }}
      />
    </div>
  )
}

export { scenarioStateActions }
export default connect(mapStateToProps)(ScenarioEditor)
